import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import GettingReady from './Instructions/GettingReady';
import ConnectedFromAnotherDevice from './Instructions/ConnectedFromAnotherDevice';
import ReadyToJoin from './Instructions/ReadyToJoin';
import FutureLink from './Instructions/FutureLink';
import InvalidLink from './Instructions/InvalidLink';
import GuestReady from './Instructions/GuestReady';
import HostReady from './Instructions/HostReady';
import StartingRoom from './Instructions/StartingRoom';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { initialiseMeeting } from '../../ChimeVideo'

import { store } from '../../../index';
import { useParams } from 'react-router-dom';
// import useChatContext from '../../../hooks/useChatContext/useChatContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '20px',
    marginBottom: '100px',
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  termsAndConditions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: '80px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-20px',
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  toolTipContainer: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      marginLeft: '0.3em',
    },
  },
}));

function ReadyComponent() {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { URLParticipantAuthentication } = useParams<{ URLParticipantAuthentication: string }>();
  const { connect: videoConnect } = useVideoContext();
  const meetingManager = useMeetingManager();

  const { roomStatus, shouldConnect, connected, isReady, roomStarted, room, connectedFromAnotherDevice } = store;

  const onReconnect = () => {
    store.setIsReady(false);
    store.setConnectedFromAnotherDevice(false);
    store.requestSocketConnect(URLParticipantAuthentication);
  };

  const onReady = () => {
    store.sendMessage({ action: 'update-status', status: 'ready' });
    store.setIsReady(true);
  };

  const onLaunchRoom = () => {
    store.setVideoActive(true);
    initialiseMeeting(meetingManager, store.meeting, store.attendee)
    // process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' &&
    //   store.features?.chat &&
    //   chatConnect(store.twilio_token);
  };

  if (isFetching || isConnecting) {
    return <></>;
  }

  // TODO: Check what variables of 'room' is being used on components that take 'room' as an input
  const renderInstruction = () => {
    if (connectedFromAnotherDevice) {
      return <ConnectedFromAnotherDevice onReconnect={onReconnect} />;
    }

    if (!room || roomStatus == 'ended' || ['ended', 'cancelled'].includes(room?.room_status)) {
      return <InvalidLink room={room} />;
    }

    if (shouldConnect && (!connected || (connected && room === null))) {
      return <GettingReady />;
    }

    if (connected && room && room.token_is_valid && room.token_is_for_today && !isReady) {
      return <ReadyToJoin onReady={onReady} room={room} />;
    }

    if (connected && room && room.token_is_valid && !room.token_is_for_today && !room.token_is_expired) {
      return <FutureLink room={room} />;
    }

    if (connected && room && room.token_is_valid && room.token_is_expired) {
      return <InvalidLink room={room} />;
    }

    if (isReady && !roomStarted && room && room.room_started === false) {
      if (room.participant_type === 'guest') return <GuestReady room={room} />;
      if (room.participant_type === 'host') return <HostReady room={room} />;

      return <p>Ready</p>;
    }

    if (roomStarted || (room && room.room_started === true)) {
      return <StartingRoom room={room} onLaunchRoom={onLaunchRoom} />;
    }

    return <p>Your link is invalid or expired</p>;
  };

  return (
    <Grid className={classes.container} container direction="row" alignItems="center">
      {renderInstruction()}
      <Hidden smUp>
        <div className={classes.termsAndConditions}>
          {store.tenantTermsAndConditionsLink ? (
            <Typography align="center">
              By participating in this consultation, you agree to the{' '}
              <a href={store.tenantTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
                terms and conditions
              </a>{' '}
              of this service
            </Typography>
          ) : null}
        </div>
      </Hidden>
    </Grid>
  );
}

export default observer(ReadyComponent);
