import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import ReadyComponent from './ReadyComponent/ReadyComponent';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { observer } from 'mobx-react-lite';

import { store } from '../../index';
import { Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 80px)', // Adjust as needed
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  sideBySide: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    maxWidth: '1800px', // Adjust as needed
    alignItems: 'center', // Vertically center the children
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  terms: {
    position: 'absolute',
    bottom: 5,
    width: '100%',
    textAlign: 'center',
    color: '#5f6368',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '5px',
      marginRight: '5px',
    },
  },
}));

function PreJoinScreens() {
  const classes = useStyles();
  const { getAudioAndVideoTracks } = useVideoContext();
  const [token, setToken] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (store.twilio_token) {
      setToken(store.twilio_token);
    }
  }, [store.twilio_token]);

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  return (
    <>
      <IntroContainer>
        <MediaErrorSnackbar error={mediaError} />
        <div className={classes.container}>
          <div className={classes.sideBySide}>
            {/*<DeviceSelectionScreen name={store.userName} token={token} />*/}
            <ReadyComponent />
          </div>
        </div>
      </IntroContainer>
      <Hidden smDown>
        {store.tenantTermsAndConditionsLink ? (
          <Typography className={classes.terms}>
            By participating in this consultation, you agree to the{' '}
            <a href={store.tenantTermsAndConditionsLink} target="_blank" rel="noopener noreferrer">
              terms and conditions
            </a>{' '}
            of this service
          </Typography>
        ) : null}
      </Hidden>
    </>
  );
}

export default observer(PreJoinScreens);
