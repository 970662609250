import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import {
  useLocalVideo,
} from 'amazon-chime-sdk-component-library-react';
import { useDevices } from '../../../hooks/chimeUseDevices'

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const lastClickTimeRef = useRef(0);
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const { hasVideoInputDevices } = useDevices();


  const doToggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideo();
    }
  }, [toggleVideo]);

  return (
    <Button
      className={props.className}
      onClick={doToggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
      startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
    >
      {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'}
    </Button>
  );
}
