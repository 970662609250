import { useState, useEffect } from 'react';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

export function useDevices() {
  const [hasVideoInputDevices, setHasVideoInputDevices] = useState(false);
  const audioVideo = useAudioVideo();

  useEffect(() => {
    const checkVideoDevices = async () => {
      if (audioVideo) {
        const videoInputDevices = await audioVideo.listVideoInputDevices();
        setHasVideoInputDevices(videoInputDevices.length > 0);
      }
    };

    checkVideoDevices();
  }, [audioVideo]);

  return { hasVideoInputDevices };
}