import { ThemeProvider } from 'styled-components';
import {
  MeetingProvider,
  lightTheme,
  useLocalVideo,
  darkTheme,
  GlobalStyles,
  VideoTileGrid,
  useMeetingManager,
  useAttendeeAudioStatus,
  useAudioVideo
} from 'amazon-chime-sdk-component-library-react';
import { observer } from 'mobx-react-lite';
import { DefaultBrowserBehavior, MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { store } from '../../index';
import { useEffect } from 'react';

async function initialiseMeetingComponent(meetingManager, meeting, attendee) {
  if (!meeting || !attendee) {
    console.error("Meeting or attendee data is missing");
    return;
  }

  try {
    const meetingSessionConfiguration = new MeetingSessionConfiguration(meeting, attendee);

    await meetingManager.join(meetingSessionConfiguration);
    await meetingManager.start();

  } catch (error) {
    console.error("Error initializing meeting:", error);
  }
}


const MyApp = observer(() => {
  const audioVideo = useAudioVideo();
  const { toggleVideo, isVideoEnabled } = useLocalVideo();

  useEffect(() => {
    if (audioVideo) {
      // Subscribe to remote attendee's video and audio
      audioVideo.realtimeSubscribeToAttendeeIdPresence((attendeeId, present) => {
        if (present) {
          console.log(`Attendee ${attendeeId} joined`);
        } else {
          console.log(`Attendee ${attendeeId} left`);
        }
      });

      // Subscribe to video tile updates
      audioVideo.videoTileDidUpdate = (tileState) => {
        if (!tileState.localTile && tileState.tileId) {
          console.log(`Displaying remote video tile for Attendee ID: ${tileState.boundAttendeeId}`);
        }
      };
    }
  }, [audioVideo]);

  return (
    <div style={{ border: '2px solid red', height: '94%', width: '100%' }}>
      <VideoTileGrid
        // noRemoteVideoView={<div>No one is sharing his video</div>}
      />
    </div>
  )
});


function ChimeVideoComponent() {
  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <MyApp />
    </ThemeProvider>
  );
}

export const ChimeVideo = observer(ChimeVideoComponent);
export const initialiseMeeting = initialiseMeetingComponent;
