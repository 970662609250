import { makeAutoObservable } from 'mobx';
import Socket from './Socket';
import { API_ROOT_URL, WEBSOCKET_URL } from '../constants';

export default class Store {
  userName: any = null;
  userType: any = null;

  videoQuality: string = 'medium';

  twilio_token: any = null;
  token: any = null;
  videoActive: boolean = false;
  attendee: any = null;
  meeting: any = null;
  roomName: any = null;
  roomStatus: any = null;
  tenantLogo: any = null;
  tenantTermsAndConditionsLink: any = null;
  tenantProjectName: any = null;

  room: any = null;

  features = {
    chat: false,
    noice_cancellation: false,
    backgrounds: false,
    recording: false,
    screenshare: {
      host: false,
      guests: false,
    },
  };

  feedback: any = {
    elements: [],
    showQuestionNumbers: false,
  };

  videoDisconnected: boolean = false;
  shouldConnect: boolean = false;
  roomStarted: boolean = false;
  connected: boolean = false;
  isReady: boolean = false;
  connectedFromAnotherDevice: boolean = false;
  requestPermissions: boolean = true;
  permissionsStep: string = 'unknown';

  socket = new Socket();

  sendMessage(message: any) {
    this.socket.sendMessage({ ...message, token: this.token });
  }

  startRoom() {
    this.roomStarted = true;
    this.sendMessage({ action: 'start-room' });
  }

  setRequestPermissions(requestPermissions: boolean) {
    this.requestPermissions = requestPermissions;
  }

  setVideoQuality(videoQuality: string) {
    this.videoQuality = videoQuality;
  }

  setPermissionsStep(permissionsStep: string) {
    this.permissionsStep = permissionsStep;
  }

  setToken(token: string) {
    this.token = token;
  }

  setTwilioToken(token: string) {
    this.twilio_token = token;
  }

  setMeeting(meeting: any) {
    this.meeting = meeting;
  }

  setAttendee(attendee: any) {
    this.attendee = attendee;
  }

  setUserName(userName: string) {
    this.userName = userName;
  }

  setVideoActive(videoActive: boolean) {
    this.videoActive = videoActive;
  }

  setRoomName(roomName: string) {
    this.roomName = roomName;
  }

  setRoomStatus(roomStatus: string) {
    this.roomStatus = roomStatus;
  }

  setTenantLogo(tenantLogo: string) {
    this.tenantLogo = tenantLogo;
  }

  setTenantTermsAndConditionsLink(tenantTermsAndConditionsLink: string) {
    this.tenantTermsAndConditionsLink = tenantTermsAndConditionsLink;
  }

  setTenantProjectName(tenantProjectName: string) {
    this.tenantProjectName = tenantProjectName;
  }

  setIsReady(isReady: boolean) {
    this.isReady = isReady;
  }

  setFeatures(features: any) {
    this.features = features;
  }

  setFeedback(feedback: any) {
    this.feedback = feedback;
  }

  setUserType(userType: any) {
    this.userType = userType;
  }

  setConnected(connected: boolean) {
    this.connected = connected;
  }

  setConnectedFromAnotherDevice(connectedFromAnotherDevice: boolean) {
    this.connectedFromAnotherDevice = connectedFromAnotherDevice;
  }

  setRoomStarted(roomStarted: boolean) {
    this.roomStarted = roomStarted;
  }

  setRoom(room: any) {
    this.room = room;
  }

  setVideoDisconnected(videoDisconnected: boolean) {
    this.videoDisconnected = videoDisconnected;

    if (videoDisconnected) {
      this.sendMessage({ action: 'update-status', status: 'disconnected_video' });
    }
  }

  setShouldConnect(shouldConnect: boolean) {
    this.shouldConnect = shouldConnect;
  }

  requestSocketConnect(token: string) {
    this.socket.connect(`${WEBSOCKET_URL}`, token);
    this.shouldConnect = true;
  }

  saveSurveyFeedback(json: any) {
    fetch(`${API_ROOT_URL}/feedback/${this.token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.ok) {
          console.log('Survey successfully saved');
        } else {
          console.log('Survey failed safely');
        }
      })
      .catch(error => {
        console.log('Survey failed unsafely');
      });
  }

  closeRoom() {
    fetch(`${API_ROOT_URL}/close-room/${this.token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: '{}',
    })
      .then(response => {
        if (response.ok) {
          this.roomStatus = 'ended';
        } else {
          console.log('room close failed safely');
        }
      })
      .catch(error => {
        console.log('room close failed unsafely');
      });
  }

  constructor() {
    makeAutoObservable(this);
  }
}
