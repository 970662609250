import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, styled } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import Store from './state/store';
import useHeight from './hooks/useHeight/useHeight';
import SimpleMenuBar from './components/MenuBar/SimpleMenuBar';
import RequestMediaPermissions from './components/PreJoinScreens/RequestMediaPermissions';
import { observer } from 'mobx-react-lite';
import {
  NotificationProvider,
  MeetingProvider,
} from 'amazon-chime-sdk-component-library-react';
import ErrorProvider from './components/ErrorProvider'

export const store = new Store();

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

const VideoApp = observer(() => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const height = useHeight();

  if (store.requestPermissions)
    return (
      <Container style={{ height: height + 'px' }}>
        <SimpleMenuBar />
        <Main>
          <RequestMediaPermissions />
        </Main>
      </Container>
    );

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <NotificationProvider>
        <ErrorProvider>
          <MeetingProvider>
            <App />
            </MeetingProvider>
        </ErrorProvider>
      </NotificationProvider>
    </VideoProvider>
  );
});

export const ReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <PrivateRoute path="/:URLParticipantAuthentication">
              <VideoApp />
            </PrivateRoute>
            <PrivateRoute path="/">
              <VideoApp />
            </PrivateRoute>
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
